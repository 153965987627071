import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import { setUser } from "../../actions";
import Tooltip from "../../components/elements/Tooltip";
import HasFeature from "../../components/helpers/HasFeature";
import confirmAction from "../../libs/confirm-action";

function Sidebar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  function isPathActive(path) {
    return function (match, location) {
      if (!location) {
        return false;
      }
      return location.pathname.startsWith(path);
    };
  }

  function logout() {
    function confirm() {
      dispatch(setUser());
      history.push("/");
    }

    return confirmAction({ confirm });
  }

  return (
    <>
      <Tooltip place="right" id="sidebar-tooltip" />
      <aside className="aside is-hidden-mobile flex-vertical">
        <ul className="sidebar-nav sidebar-top flex-grow">
          {/* SCHEDULING */}
          <li>
            <NavLink
              to="/scheduling/jobs"
              data-cy="sidebar-navlink-schduling"
              activeClassName="active"
              isActive={isPathActive("/scheduling")}
              data-tip={intl.formatMessage({
                id: "app.components.sidebar.scheduling",
                defaultMessage: "Scheduling",
              })}
              data-for="sidebar-tooltip"
            >
              <img
                src={
                  require("../../images/sidebar/sb-job-board-icn.svg").default
                }
                alt=""
                width="20"
                height="20"
              />
            </NavLink>
          </li>
          {/* PLANNING */}
          <div className="is-clearfix" />
          <HasFeature feature="SCHEDULER">
            <li>
              <NavLink
                to="/planning/scheduler"
                data-cy="sidebar-navlink-planning"
                activeClassName="active"
                isActive={isPathActive("/planning")}
                data-tip={intl.formatMessage({
                  id: "app.components.sidebar.planning",
                  defaultMessage: "Planning",
                })}
                data-for="sidebar-tooltip"
              >
                <img
                  src={
                    require("../../images/sidebar/sb-planning-icn.svg").default
                  }
                  alt=""
                  width="20"
                  height="20"
                />
              </NavLink>
            </li>
          </HasFeature>
          {/* STOCK */}
          <div className="is-clearfix" />
          <HasFeature feature="STOCK:*">
            <li>
              <NavLink
                to="/stock/items"
                data-cy="sidebar-navlink-stock"
                activeClassName="active"
                isActive={isPathActive("/stock")}
                data-tip={intl.formatMessage({
                  id: "app.components.sidebar.stock",
                  defaultMessage: "Stock",
                })}
                data-for="sidebar-tooltip"
              >
                <img
                  src={require("../../images/sidebar/sb-stock-icn.svg").default}
                  alt=""
                  width="20"
                  height="20"
                />
              </NavLink>
            </li>
          </HasFeature>
          {/* LIVE */}
          <div className="is-clearfix" />
          <li>
            <NavLink
              to="/live/jobs"
              activeClassName="active"
              data-cy="sidebar-navlink-live"
              isActive={isPathActive("/live")}
              data-tip={intl.formatMessage({
                id: "app.components.sidebar.factory_live",
                defaultMessage: "Factory live",
              })}
              data-for="sidebar-tooltip"
            >
              <img
                src={require("../../images/sidebar/sb-factory-icn.svg").default}
                alt=""
                width="20"
                height="20"
              />
            </NavLink>
          </li>
          {/* HISTORY */}
          <div className="is-clearfix" />
          <li>
            <NavLink
              to="/history/jobs"
              activeClassName="active"
              data-cy="sidebar-navlink-history"
              isActive={isPathActive("/history")}
              data-tip={intl.formatMessage({
                id: "app.components.sidebar.history",
                defaultMessage: "Factory performance",
              })}
              data-for="sidebar-tooltip"
            >
              <img
                src={
                  require("../../images/sidebar/sb-dashboard-icn.svg").default
                }
                alt=""
                width="20"
                height="20"
              />
            </NavLink>
          </li>
          {/* ANALYTICS */}
          <div className="is-clearfix" />
          <HasFeature feature="ANALYTICS:*">
            <li>
              <NavLink
                to="/analytics/jobs"
                activeClassName="active"
                data-cy="sidebar-navlink-analytics"
                isActive={isPathActive("/analytics")}
                data-tip={intl.formatMessage({
                  id: "app.components.sidebar.analytics",
                  defaultMessage: "Factory analytics",
                })}
                data-for="sidebar-tooltip"
              >
                <img
                  src={
                    require("../../images/sidebar/sb-analytics-icn.svg").default
                  }
                  alt=""
                  width="20"
                  height="20"
                />
              </NavLink>
            </li>
          </HasFeature>
          {/* LISTINGS */}
          <div className="is-clearfix" />
          <li>
            <NavLink
              to="/listings/items"
              activeClassName="active"
              data-cy="sidebar-navlink-listings"
              isActive={isPathActive("/listings")}
              data-tip={intl.formatMessage({
                id: "app.components.sidebar.listings",
                defaultMessage: "Listings",
              })}
              data-for="sidebar-tooltip"
            >
              <img
                src={require("../../images/sidebar/sb-lists-icn.svg").default}
                alt=""
                width="20"
                height="20"
              />
            </NavLink>
          </li>
          {/* C4I */}
          <HasFeature feature="C4I:*">
            <li>
              <NavLink
                to="/connect4i/configuration/machines"
                activeClassName="active"
                data-cy="sidebar-navlink-listings"
                isActive={isPathActive("/connect4i")}
                data-tip={intl.formatMessage({
                  id: "app.components.sidebar.c4i",
                  defaultMessage: "Connect4i",
                })}
                data-for="sidebar-tooltip"
              >
                <img
                  src={
                    require("../../images/sidebar/sb-connect-icn.svg").default
                  }
                  alt=""
                  width="20"
                  height="20"
                />
              </NavLink>
            </li>
          </HasFeature>
        </ul>
        <ul className="sidebar-nav sidebar-bottom">
          <li>
            <NavLink
              to="/settings/configuration/general"
              data-cy="sidebar-navlink-configuration"
              activeClassName="active"
              isActive={isPathActive("/settings")}
              data-tip={intl.formatMessage({
                id: "app.components.sidebar.settings",
                defaultMessage: "Settings",
              })}
              data-for="sidebar-tooltip"
            >
              <span className="icon is-large">
                <i className="icon-sb-settings-icn" />
              </span>
            </NavLink>
          </li>
          <li>
            <a
              onClick={logout}
              data-cy="sidebar-navlink-signout"
              data-tip={intl.formatMessage({
                id: "app.components.sidebar.logout",
                defaultMessage: "Logout",
              })}
              data-for="sidebar-tooltip"
            >
              <span className="icon is-large">
                <i className="icon-sb-signout-icn" />
              </span>
            </a>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;
