import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

const StyledNavbar = styled.nav`
  box-shadow: 0 0.2rem 1.3rem 0.1rem rgba(0, 0, 0, 0.33);

  color: white !important;
  .navbar-menu .navbar-item {
    color: white !important;
    &.active,
    &:focus,
    &:hover {
      background-color: rgba(0, 0, 0, 0.2) !important;
      font-weight: initial;
    }
  }
  .navbar-end {
    margin-right: 2rem;
    align-items: center;
    .navbar-profile {
      color: white;
      line-height: 1.5;
      font-size: 1.6rem;
    }
  }
`;

function NavBar({ children }) {
  const { user } = useSelector(({ user }) => user.user);
  const { locale } = useIntl();

  return (
    <StyledNavbar className="navbar is-fixed-top">
      <div
        className="navbar-brand"
        style={{
          fontFamily:
            locale === "ru" ? "Impact, Charcoal, sans-serif" : "inherit",
          fontWeight: 500,
        }}
      >
        <Link className="navbar-item brand" to="/scheduling/jobs">
          <FormattedMessage
            id="app.shared.brand"
            defaultMessage="Agile Factory"
          />
        </Link>
      </div>
      <div className="navbar-menu">{children}</div>
      <div className="navbar-end">
        <div className="navbar-profile" data-cy="navbar-user">
          {user.firstName} {user.lastName}
        </div>
      </div>
    </StyledNavbar>
  );
}

NavBar.propTypes = {
  children: PropTypes.node,
};
export default NavBar;
