import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import LoginForm from "./components/LoginForm.form";

function LoginPage() {
  const { user } = useSelector(({ user }) => user);
  const { locale } = useIntl();

  return (
    <div className="hero-body">
      {user && <Redirect to="/" />}
      <div className="container has-text-centered">
        <h1
          className="title"
          style={{
            fontFamily:
              locale === "ru" ? "Impact, Charcoal, sans-serif" : "inherit",
            fontWeight: 500,
          }}
        >
          <FormattedMessage
            id="app.pages.login.title"
            defaultMessage="Agile Factory"
          />
        </h1>
        <h2 className="subtitle">
          <FormattedMessage
            id="app.pages.login.subtitle"
            defaultMessage="Proceed with sign in"
          />
        </h2>
        <div className="columns">
          <div className="column is-4 is-offset-4">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}

LoginPage.propTypes = {};

export default LoginPage;
