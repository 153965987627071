import React, { Suspense } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import classNames from "classnames";
import { Route, Switch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";

import FullpageLoader from "../loaders/FullpageLoader";
import NavBar from "../components/elements/NavBar";
import Sidebar from "./components/Sidebar";
import SchedulingNavBar from "./components/SchedulingNavBar";
import PlanningNavBar from "./components/PlanningNavBar";
import LiveNavBar from "./components/LiveNavBar";
import HistoryNavBar from "./components/HistoryNavBar";
import ListingsNavBar from "./components/ListingsNavBar";
import Connect4iNavBar from "./components/Connect4iNavBar";
import SettingsNavBar from "./components/SettingsNavBar";
import StockNavBar from "./components/StockNavBar";
import Error from "../components/helpers/Error";
import AnalyticsNavBar from "./components/AnalyticsNavBar";
import Context from "./DefaultLayout.context";
import { languageWithoutRegionCode as lang } from "../config/localization";

const CONFIGURATION_QUERY = loader("./DefaultLayout.query.graphql");

function DefaultLayout({ children, className }) {
  const { data, loading, error } = useQuery(CONFIGURATION_QUERY);

  if (loading && !data) {
    return <FullpageLoader />;
  }
  if (error) {
    return <Error error={error} />;
  }
  const { currency, plugins } = data;
  return (
    <Context.Provider value={{ currency, plugins }}>
      <div className={classNames("default-layout wrapper", className)}>
        <Helmet htmlAttributes={{ class: "default-layout", lang }} />
        <Switch>
          <Route path="/scheduling" component={SchedulingNavBar} />
          <Route path="/stock" component={StockNavBar} />
          <Route path="/planning" component={PlanningNavBar} />
          <Route path="/live" component={LiveNavBar} />
          <Route path="/history" component={HistoryNavBar} />
          <Route path="/analytics" component={AnalyticsNavBar} />
          <Route path="/listings" component={ListingsNavBar} />
          <Route path="/connect4i" component={Connect4iNavBar} />
          <Route path="/settings" component={SettingsNavBar} />
          <Route path="/" component={NavBar} />
        </Switch>
        <Sidebar />
        <div className="main-frame" style={{ height: "calc(100vh - 6rem)", marginLeft: "6rem" }}>
          <Suspense fallback={<FullpageLoader />}>{children}</Suspense>
        </div>
      </div>
    </Context.Provider>
  );
}

DefaultLayout.propTypes = {
  navbar: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};
export default DefaultLayout;
